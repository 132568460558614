import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { formatUnits } from 'ethers';

import { Layout } from '../../components/Layout/Layout';
import { BorrowDetailsActionsSection } from '../../components/Borrow/BorrowDetails/BorrowDetailsActionsSection';
import { BorrowDetailsSummarySection } from '../../components/Borrow/BorrowDetails/BorrowDetailsSummarySection';
import { useBorrowDetailsStyle } from './BorrowDetails.style';
import { BorrowDetailsBalanceManagement } from '../../components/Borrow/BorrowDetails/BorrowDetailsBalanceManagement';
import { Store } from '../../store';
import { getTransformedUserBorrow } from 'src/utils/helpers';
import { UserBorrowItem } from 'src/components/Borrow/UserBorrows';

export enum BorrowDetailState {
    DEFAULT = 'DEFAULT',
    CLOSE_CREDIT = 'CLOSE_CREDIT',
    DECREASE_DEBT = 'DECREASE_DEBT',
    BORROW_MORE = 'BORROW_MORE',
}

export const BorrowDetails: FC = () => {
    const classes = useBorrowDetailsStyle();
    const { address } = useParams();
    const navigate = useNavigate();

    const { userBorrows } = useSelector((state: Store) => ({
        userBorrows: state.borrows.userBorrows,
    }));

    const [userBorrow, setUserBorrow] = useState<UserBorrowItem>();
    const [riskFactor, setRiskFactor] = useState<string>('0');
    const [accruedInterest, setAccruedInterest] = useState<string>('0');

    useEffect(() => {
        const currentBorrow = userBorrows.find((borrow) => borrow.address === address);

        if (currentBorrow) {
            const transformedUserBorrow = getTransformedUserBorrow(currentBorrow);

            setUserBorrow(transformedUserBorrow);
            const rf =
                transformedUserBorrow?.maintenance?.riskFactor ||
                transformedUserBorrow?.history[transformedUserBorrow.history.length - 1]?.data
                    ?.riskFactor ||
                '0';

            setRiskFactor(rf);
            const ai =
                transformedUserBorrow?.maintenance?.accruedInterest ||
                transformedUserBorrow?.history[transformedUserBorrow.history.length - 1]?.data
                    ?.accruedInterest ||
                '0';
            setAccruedInterest(ai);
        } else {
            navigate('/borrow');
        }
    }, [address, userBorrows]);

    const [pageState, setPageState] = useState(BorrowDetailState.DEFAULT);
    const location = useLocation();

    const getContent = () => {
        if (
            pageState === BorrowDetailState.BORROW_MORE ||
            pageState === BorrowDetailState.DECREASE_DEBT
        ) {
            return (
                <BorrowDetailsBalanceManagement
                    pageState={pageState}
                    setPageState={setPageState}
                    assetName={userBorrow?.symbol as any}
                />
            );
        }

        if (!userBorrow) {
            return (
                <div>
                    <Spin />
                </div>
            );
        }

        return (
            <>
                <BorrowDetailsActionsSection
                    pageState={pageState}
                    marginAccountAddress={userBorrow.address}
                    setPageState={setPageState}
                    amount={formatUnits(userBorrow.amount, userBorrow.decimals)}
                    accumulatedInterest={formatUnits(accruedInterest, userBorrow.decimals)}
                    leverageAddress={userBorrow.leverageAddress}
                />
                <BorrowDetailsSummarySection
                    assetName={userBorrow.symbol}
                    walletAddress={userBorrow.address}
                    marginAccount={userBorrow.address}
                    riskFactor={riskFactor}
                    apy={userBorrow.apy}
                    accountValue={formatUnits(userBorrow.amount, userBorrow.decimals)}
                    accumulatedInterest={formatUnits(accruedInterest, userBorrow.decimals)}
                />
            </>
        );
    };

    const getLastBreadcrumbText = () => {
        if (pageState === BorrowDetailState.CLOSE_CREDIT) {
            return 'Close Credit Account';
        }

        if (pageState === BorrowDetailState.BORROW_MORE) {
            return 'Borrow More';
        }

        return 'Decrease Debt';
    };

    return (
        <div>
            <Layout>
                <div className={classes.breadcrumbs}>
                    <Link to='/borrow' className={classes.link}>
                        Fixed Rate
                    </Link>
                    <ArrowIcon className={classes.arrow} />
                    <Link
                        to={`/borrow/${location.pathname.split('/')[2]}`}
                        className={classes.link}
                        onClick={() => setPageState(BorrowDetailState.DEFAULT)}
                    >
                        <div>{`${userBorrow?.symbol || ''} Credit Account`}</div>
                    </Link>
                    {pageState !== BorrowDetailState.DEFAULT && (
                        <>
                            <ArrowIcon className={classes.arrow} />
                            <Link to={location.pathname} className={classes.link}>
                                {getLastBreadcrumbText()}
                            </Link>
                        </>
                    )}
                </div>
                <div className={classes.content}>{getContent()}</div>
            </Layout>
        </div>
    );
};
