import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { ReactComponent as CopyIcon } from '@blink/assets/src/icons/copy.svg';
import { RadioChangeEvent, Spin, Tooltip, message } from 'antd';
import { ButtonStroke } from '@blink/components/src/Button/ButtonStroke';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { ButtonGroup, TextButton } from '@blink/components';
import { copyToClipboard, shortFormatNumber, WalletHelper } from '@blink/components/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Etherscan } from '@blink/assets/src/icons/etherscan.svg';
import { ReactComponent as Loader } from '@blink/assets/src/icons/loaderDash.svg';
import { ReactComponent as ExclamationIcon } from '@blink/assets/src/icons/exclamation.svg';

import { usePoolDetailsStyle } from './Pool.style';
import { Description } from 'src/components/PoolDetails/Description/Description';
import { Allowed } from 'src/components/PoolDetails/Allowed/Allowed';
import { Counterparties } from 'src/components/PoolDetails/Counterparties/Counterparties';
import { Store } from 'src/store';
import { Agreement, ROLE } from 'src/api/borrow';
import { AgreementDefault } from 'src/store/agreements';
import { getAgreementsAction } from 'src/store/agreements/actions';
import { showModalAction } from 'src/store/modals/actions';
import { ModalName } from 'src/store/modals/constants';

export const enum ContentListType {
    DESCRIPTION = 'description',
    ALLOWED = 'allowed',
    COUNTERPARTIES = 'counterparties',
}

const contentList = [
    {
        text: 'Description',
        value: ContentListType.DESCRIPTION,
    },
    {
        text: 'Allowed Tokens',
        value: ContentListType.ALLOWED,
    },
    {
        text: 'Counterparties',
        value: ContentListType.COUNTERPARTIES,
    },
];

export const Pool: FC = () => {
    const classes = usePoolDetailsStyle();
    const { address } = useParams();
    const [searchParams] = useSearchParams();
    const role = searchParams.get('role');
    const [agreement, setAgreement] = useState<Agreement>(AgreementDefault);

    const dispatch = useDispatch();

    const { tokens, agreements, type } = useSelector((state: Store) => ({
        tokens: state.tokens.tokens,
        agreements: state.agreements.agreements,
        type: state.wallets.active.type,
    }));

    useEffect(() => {
        if (agreements[0].address === '') {
            dispatch(getAgreementsAction());
        } else {
            const currentAgreement = agreements.find(
                (agreement) => agreement.address === address && agreement.role === role,
            );

            setAgreement(currentAgreement!);
        }
    }, [type, agreements]);

    const [selectedTab, setSelectedTab] = useState(ContentListType.DESCRIPTION);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSelectedBorrowStatusListChange = (e: RadioChangeEvent) => {
        setSelectedTab(e.target.value);
        setLoading(true);
    };

    const location = useLocation();

    const handleCopyAddress = () => {
        copyToClipboard(address || '');
        message.success('Copied to clipboard!');
    };

    const handleBtnClick = () => {
        if (agreement.role === ROLE.LENDER) {
            dispatch(
                showModalAction({
                    modalName: ModalName.LIQUIDITY_MODAL,
                    modalTitle: 'Supply Liquidity',
                    modalProps: {
                        tokenSymbol: agreement.leverage.symbol,
                        balance: agreement.leverage.balance,
                        currentApy: agreement.apy,
                        totalDeposited: shortFormatNumber({
                            amount: Number(agreement.totalDeposited),
                        }),
                        thresholdOnTotalDeposit: agreement.thresholdOnTotalDeposit,
                        agreementAddress: address,
                        tokenAddress: agreement.leverage.address,
                    },
                }),
            );

            return;
        } else if (agreement.role === ROLE.BORROWER) {
            const leverage = tokens.find((item) => item.id === agreement.leverageTokenId);

            dispatch(
                showModalAction({
                    modalName: ModalName.BORROW_MODAL,
                    modalTitle: `Borrow ${leverage?.symbol}`,
                    modalProps: {
                        tokenSymbol: leverage?.symbol,
                        agreementAddress: address,
                        tokenAddress: leverage?.address,
                    },
                }),
            );

            return;
        }
    };

    const handleEtherscan = () => {
        window.open(`https://etherscan.io/address/${address}`, '_blank');
    };

    const getContent = () => {
        if (loading) {
            setLoading(false);
            return (
                <div>
                    <Spin />
                </div>
            );
        }

        switch (selectedTab) {
            case ContentListType.DESCRIPTION:
                return <Description description={agreement.description} />;
            case ContentListType.ALLOWED:
                return <Allowed tokens={agreement.stressTestMatrix} />;
            case ContentListType.COUNTERPARTIES:
                return <Counterparties />;

            default:
                break;
        }
    };

    return (
        <>
            {!type || (agreement && agreement.address === '') ? (
                <div className={classes.loader}>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className={classes.top}>
                        <div className={classes.breadcrumbs}>
                            <Link to='/' className={classes.link}>
                                Explore
                            </Link>
                            <ArrowIcon className={classes.arrow} />
                            <Link to={`/`} className={classes.link}>
                                <div>Pools</div>
                            </Link>
                            <ArrowIcon className={classes.arrow} />
                            <Link to={location.pathname} className={classes.link}>
                                <div className={classes.addressWrapper}>
                                    <div>
                                        {agreement.title}{' '}
                                        <span>{`(${WalletHelper.addressShortName(
                                            agreement.address,
                                        )})`}</span>
                                    </div>
                                    <TextButton
                                        onClick={handleCopyAddress}
                                        className={classes.textButton}
                                    >
                                        <CopyIcon />
                                    </TextButton>
                                </div>
                            </Link>
                        </div>
                        <div className={classes.heading}>
                            <div className={classes.content}>
                                <div className={classes.title}>
                                    <div className={classes.titleText}>{agreement.title}</div>
                                </div>
                                <div className={classes.etherscan} onClick={handleEtherscan}>
                                    <Etherscan />
                                </div>
                            </div>
                            <div className={classes.buttonWrapper}>
                                <ButtonStroke
                                    isPrimary
                                    size={ButtonSize.md}
                                    className={classes.borrowButton}
                                    disabled={false}
                                    onClick={handleBtnClick}
                                >
                                    {agreement.role === ROLE.LENDER ? 'Supply' : 'Borrow'}
                                </ButtonStroke>
                            </div>
                        </div>
                    </div>

                    <div className={classes.tabs}>
                        <ButtonGroup
                            items={contentList}
                            value={selectedTab}
                            handleChange={handleSelectedBorrowStatusListChange}
                            className={classes.infoButtons}
                        />
                    </div>
                    <div className={classes.main}>
                        <div className={classes.contentWrapper}>{getContent()}</div>
                        <div className={classes.tableWrapper}>
                            <table className={classes.table}>
                                <thead>
                                    <tr className={classes.tr}>
                                        <th className={`${classes.asset}`}>Pool Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={`${classes.tr}`}>
                                        <td className={classes.firstTd}>
                                            <div className={classes.param}>
                                                {agreement.role === ROLE.LENDER
                                                    ? 'Supply APY'
                                                    : 'Borrow APY'}
                                            </div>
                                            <div
                                                className={classes.subtitle}
                                            >{`APY: ${agreement.apy}%`}</div>
                                        </td>
                                    </tr>
                                    <tr className={`${classes.tr}`}>
                                        <td className={classes.firstTd}>
                                            <div className={classes.param}>
                                                <div>Liquidation Fee</div>
                                                <Tooltip
                                                    rootClassName={classes.tooltip}
                                                    placement='right'
                                                    title={
                                                        'A penalty charged when your position is liquidated due to insufficient collateral.'
                                                    }
                                                    arrow={{ pointAtCenter: true }}
                                                >
                                                    <ExclamationIcon />
                                                </Tooltip>
                                            </div>
                                            <div
                                                className={classes.subtitle}
                                            >{`Fee: ${agreement.liquidationFee}%`}</div>
                                        </td>
                                    </tr>
                                    <tr className={`${classes.tr}`}>
                                        <td className={classes.firstTd}>
                                            <div className={classes.param}>Risk Factor values</div>
                                            <div
                                                className={classes.subtitle}
                                            >{`Margin Call: ${agreement.marginCallThreshold}`}</div>
                                            <div className={classes.subtitle}>
                                                {`Liquidation: ${agreement.liquidationThreshold}`}{' '}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
