import * as actions from './actions';
import { GET_AGREEMENTS, SET_AGREEMENTS } from './constants';
import { Agreement, ROLE } from 'src/api/borrow';

export type AgreementState = {
    agreements: Agreement[];
    agreementsLoading: boolean;
};

export const AgreementDefault = {
    address: '',
    apy: '0',
    icon: '',
    leverageTokenId: 0,
    role: ROLE.OWNER,
    title: '',
    thresholdOnTotalDeposit: '',
    totalDeposited: '',
    description: '',
    collaterals: [''],
    lenders: [''],
    borrowers: [''],
    leverage: {
        id: 0,
        apyRewards: '0',
        address: '',
        name: '',
        symbol: '',
        balance: '',
        decimals: 0,
    },
    liquidationFee: '',
    liquidationThreshold: '',
    marginCallThreshold: '',
    stressTestMatrix: [{ id: 0, percent: '', address: '' }],
};

const defaultState: AgreementState = {
    agreements: [AgreementDefault],
    agreementsLoading: false,
};

type OrderAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function agreements(state = defaultState, action: OrderAction): AgreementState {
    switch (action.type) {
        case GET_AGREEMENTS: {
            return {
                ...state,
                agreementsLoading: true,
            };
        }
        case SET_AGREEMENTS: {
            return {
                ...state,
                agreements: action.payload,
                agreementsLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
