import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const usePoolDetailsStyle = createUseStyles({
    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        margin: `${spacing['05']} 0`,
        color: color.content.secondary,
        fontSize: fontSize.lg,
        gap: spacing['03'],
    },
    link: {
        color: color.content.secondary,
        '&:hover': {
            color: color.content.secondary,
        },
        '&:last-of-type': {
            color: color.content.secondary,
        },
    },
    arrow: {
        transform: 'rotate(-90deg)',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        gap: spacing['07'],
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: spacing['05'],
        marginRight: spacing['06'],
    },
    borrowButton: {
        width: 100,
    },
    heading: {
        display: 'flex',
        padding: `${spacing['06']} 0px`,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        gap: spacing['04'],
    },
    titleText: {
        color: color.content.primary,
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '40px',
        letterSpacing: '-0.64px',
    },
    infoButtons: {
        width: 'fit-content',
        minWidth: 440,
        border: 'none !important',
        '& > label': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 48,
            flex: '1 0 0',
            padding: `${spacing['04']} ${spacing['05']}`,
            paddingInline: '0px !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderTop: 'none !important',
            borderRadius: '0 !important',
            gap: 4,
            boxShadow: 'none !important',
            '&.ant-radio-button-wrapper:not(:first-child)::before': {
                width: '0px !important',
            },
            '& > span': {
                display: 'flex',
            },
            '&:active': {
                borderBottom: `2px solid ${color.border.accent} !important`,
            },
        },
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: 320,
        overflowX: 'auto',
        background: color.background.primary,
        '& th': {
            fontWeight: 500,
        },
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        background: color.background.primary,
        border: `1px solid ${color.border.opaque}`,
    },
    tr: {
        borderBottom: `1px solid ${color.border.opaque}`,
        height: 52,
        '& span': {
            fontWeight: 500,
        },
        '& > th': {
            fontWeight: 'normal',
            textAlign: 'left',
            color: color.content.primary,
            width: '20%',
            background: color.background.secondary,
        },

        '& > td:last-child, th:last-child': {
            paddingRight: spacing['07'],
        },
        '& > th:first-child, td:first-child': {
            paddingLeft: spacing['06'],
        },
    },
    asset: {
        width: 250,
    },
    firstTd: {
        padding: `${spacing['04']} ${spacing['05']}`,
    },
    rewardAmount: {
        paddingLeft: '20px',
    },
    param: {
        display: 'flex',
        gap: 2,
        overflow: 'hidden',
        color: color.content.primary,
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '-0.42px',
    },
    subtitle: {
        overflow: 'hidden',
        color: color.content.secondary,
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.42px',
    },
    textButton: {
        color: `${color.content.secondary} !important`,
        width: 'fit-content',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        height: 44,
        transition: 'none',
        border: 'none',
        '& path': {
            fill: color.content.secondary,
        },
        '&:hover': {
            color: `${color.content.primary} !important`,
            backgroundColor: 'transparent !important',
            '& path': {
                fill: color.border.selected,
            },
        },
    },
    addressWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
    },
    top: {
        display: 'flex',
        padding: `${spacing['08']} 10% ${spacing['10']}`,
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    tabs: {
        padding: '0 10%',
        borderBottom: `1px solid ${color.border.opaque}`,
        '& .ant-radio-button-wrapper': {
            borderBottom: `2px solid transparent`,
            '& > div': {
                display: 'none',
            },
        },
        '& .ant-radio-button-wrapper.ant-radio-button-wrapper-checked': {
            borderBottom: `2px solid ${color.border.accent}`,
        },
    },
    main: {
        display: 'flex',
        padding: `${spacing['09']} 10%`,
        alignSelf: 'stretch',
        gap: spacing['06'],
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
    },
    etherscan: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    loader: {
        display: 'flex',
        width: '100%',
        height: 300,
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            animation: '$rotate 5s linear infinite',
        },
    },
    tooltip: {
        fontSize: 12,
        minWidth: 88,
        maxWidth: 264,
        '&.ant-tooltip .ant-tooltip-inner': {
            backgroundColor: color.background.inversePrimary,
            padding: `${spacing['03']} ${spacing['04']}`,
            borderRadius: 4,
            border: 'none',
            lineHeight: '16px',
        },
    },
});
