import { ThemeConfig } from 'antd/es/config-provider/context';

// variables

export const spacing = {
    '01': '2px',
    '02': '4px',
    '03': '8px',
    '04': '12px',
    '05': '16px',
    '06': '20px',
    '07': '24px',
    '08': '32px',
    '09': '40px',
    '10': '48px',
    '11': '64px',
    '12': '80px',
    '13': '96px',
    '14': '120px',
};

const radiusBase = 2;
export const radius = {
    overall: '4px',
    xs: `${radiusBase}px`, // 2
    md: `${radiusBase * 3}px`, // 6
    lg: `${radiusBase * 4}px`, // 8
};

//typography
export const fontSize = {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '17px',
    xl: '21px',
    xxl: '24px',
};

export const fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
};

export const lineHeight = {
    xs: 1.2,
    sm: 1.4,
    md: 1.5,
    lg: 1.6,
};

//colors
export const color = {
    background: {
        primary: '#FFFFFF',
        secondary: '#F9F9F9',
        tertiary: '#FFFFFF',
        fourth: '#F2F2F2',
        inversePrimary: '#000000',
        inverseSecondary: '#262626',
        disabled: '#F9F9F9',
        accent: '#4732D4',
        positive: '#2DCA72',
        warning: '#FFB800',
        negative: '#DA1E28',
        lightAccent: '#EDEAFB',
        lightPositive: '#EAFAF1',
        lightWarning: '#FFF8E5',
        lightNegative: '#FBE8E9',
        alwaysDark: '#000000',
        alwaysLight: '#FFFFFF',
        overlayDark: 'rgba(0, 0, 0, 0.08)',
    },
    border: {
        opaque: '#E6E6E6',
        tranparent: 'rgba(0, 0, 0, 0.08)',
        selected: '#000000',
        inverseOpaque: '#404040',
        inverseTransparent: 'rgba(255, 255, 255, 0.2)',
        inverseSelected: '#FFFFFF',
        stateDisable: '#D9D9D9',
        accent: '#4732D4',
        accent1: '#2990FF',
        positive: '#2DCA72',
        warning: '#FFB800',
        negative: '#DA1E28',
        accentLight: '#DAD6F6',
    },
    content: {
        primary: '#000000',
        secondary: '#535353',
        tertiary: '#6C6C6C',
        fourth: '#808080',
        inversePrimary: '#FFFFFF',
        inverseSecondary: '#E6E6E6',
        inverseTertiary: '#CBCBCB',
        stateDisable: '#D9D9D9',
        onColor: '#FFFFFF',
        onColorInverse: '#000000',
        accent: '#4732D4',
        lightAccent: '#EDEAFB',
        accent1: '#2990FF',
        negative: '#DA1E28',
        warning: '#FFB800',
        positive: '#2DCA72',
    },
    chartLine2: '#4732D4',
};

// -------- Theme Ant Variables Start --------
const themeAntToken: ThemeConfig = {
    token: {
        fontSize: 14,
        fontSizeLG: 17,
        fontSizeXL: 20,
        colorPrimary: color.content.primary,
        colorBgLayout: color.background.primary,
        fontFamily: 'Inter',
        colorText: color.content.primary,
        colorBgElevated: color.background.secondary,
        colorBgContainer: color.background.secondary,
        colorTextQuaternary: color.content.primary,
        colorPrimaryBg: 'none',
    },
};

const themeAntComponents: ThemeConfig = {
    components: {
        Tabs: {
            colorPrimary: color.content.primary,
            fontSize: themeAntToken.token?.fontSizeLG,
            colorText: color.content.secondary,
        },
        Dropdown: {
            paddingXS: 0,
            paddingXXS: 0,
            marginXXS: 0,
            marginXS: 0,
            controlPaddingHorizontal: 0,
        },
        Menu: {
            fontSize: themeAntToken.token?.fontSizeLG,
        },
        Input: {
            colorBorder: color.border.opaque,
            borderRadius: radiusBase * 2, // 4
            fontSize: 14,
            fontSizeLG: 17,
            lineHeight: 1.4,
            lineHeightLG: 1.4,
            colorTextPlaceholder: color.content.secondary,
        },
        Steps: {
            navArrowColor: color.content.secondary,
            iconSize: 24,
        },
    },
};

export const themeAnt: ThemeConfig = {
    ...themeAntComponents,
    ...themeAntToken,
};

// -------- Theme Ant Variables End --------
