import React from 'react';
import { Collapse } from 'antd';
import { ReactComponent as CaretDown } from '@blink/assets/src/icons/caret-down.svg';
import { concatClasses } from '@blink/components/src/utils';

import { Layout } from '../Layout/Layout';
import { useFaqsStyles } from './Faqs.styles';

const { Panel } = Collapse;

export const Faqs = () => {
    const classes = useFaqsStyles();

    const expandIcon = ({ isActive }: { isActive?: boolean }) => (
        <CaretDown className={concatClasses(classes.caret, isActive ? classes.activeCaret : '')} />
    );

    return (
        <div className={classes.root}>
            <Layout>
                <div className={classes.title}>FAQ's</div>
                <Collapse expandIcon={expandIcon} ghost>
                    <Panel
                        className={classes.title}
                        header='How can I earn with Arkis Protocol?'
                        key='1'
                    >
                        <p>
                            Simply decide on chain, token, and type of pool (Fixed vs. Profit
                            Sharing) - and provide the liquidity. No need to manage your position or
                            worry about liquidations - your interest is accrued on your account.
                        </p>
                    </Panel>
                    <Panel
                        className={classes.title}
                        header='How Fixed Rate Pools work, and where yield comes from?'
                        key='2'
                    >
                        <p>
                            Suppliers provide their assets into Fixed Rate Pools, which Borrowers
                            utilize for their leveraged trading operations. At the same time,
                            Borrowers pay interest (Base APY) to Suppliers for using the leverage.
                            Secondly, Suppliers receive extra rewards (Reward APY) in Arkis tokens
                            for providing assets into pools.
                        </p>
                    </Panel>
                    <Panel
                        className={classes.title}
                        header='How do Profit Sharing Pools work?'
                        key='3'
                    >
                        <p>
                            Profit Sharing Pools work in the same fashion as Fixed Rate Pools with
                            one difference - Suppliers receive 2% of the trading profit generated by
                            Borrowers. Profit Sharing Pools have lower Base APY compared to Fixed
                            Rate Pools however, if Borrowers generate big enough profit, their
                            Profit Sharing APY may result in high Total APY = Base APY + Profit
                            Sharing APY + Rewards APY. At the same time, Borrowers pay lower
                            interest for leverage in Profit Sharing Pools but need give up 2% of the
                            trading profit when Margin Account is closed.
                        </p>
                    </Panel>
                </Collapse>
            </Layout>
        </div>
    );
};
