import React from 'react';
import { useSelector } from 'react-redux';

import { useAllowedStyle } from './Allowed.style';
import { Store } from 'src/store';
import { StressTestToken } from 'src/api/borrow';

interface AllowedProps {
    tokens: StressTestToken[];
}

export const Allowed: React.FC<AllowedProps> = ({ tokens }) => {
    const classes = useAllowedStyle();

    const tokensInfo = useSelector((state: Store) => state.tokens.tokens);

    return (
        <div className={classes.tableWrapper}>
            <table className={classes.table}>
                <thead>
                    <tr className={classes.tr}>
                        <th>Assets</th>
                        <th>Stress Tested</th>
                        {/* <th>Exposure Constraints</th>  ToDo -when information will be available*/}
                    </tr>
                </thead>
                <tbody>
                    <>
                        {tokens.map((item, key) => {
                            const symbol = tokensInfo.find(
                                (token) => token.address === item.address,
                            )?.symbol;

                            return (
                                <tr key={key} className={classes.tr}>
                                    <td className={classes.firstTd}>{symbol}</td>
                                    <td>-{Number(item.percent) * 100}%</td>
                                    {/* <td>{item.constraints}%</td> ToDo -when information will be available*/}
                                </tr>
                            );
                        })}
                    </>
                </tbody>
            </table>
        </div>
    );
};
