import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStroke, UserControls } from '@blink/components';

import { reconnectIfWalletAvailableSaga } from 'src/store/wallets/actions';
import { useConnectWalletStyles } from './ConnectWallet.style';
import { LoggedInProvider } from '../LoggedInProvider';
import { SelectNetwork } from '../SelectNetwork';
import { ConnectedWalletInfo } from './ConnectWalletInfo';
import { showModalAction } from '../../store/modals/actions';
import { ModalName } from '../../store/modals/constants';
import { getMeAction } from 'src/store/me/actions';
import { useProviders } from 'src/context/provider';

interface ConnectWalletProps {
    handleSetBannerState: (value: boolean) => void;
}

export const ConnectWallet: React.FC<ConnectWalletProps> = ({ handleSetBannerState }) => {
    const providers = useProviders();
    const classes = useConnectWalletStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reconnectIfWalletAvailableSaga());
    }, [providers]);

    useEffect(() => {
        dispatch(getMeAction());
    }, []);

    const handleConnectWalletClick = () =>
        dispatch(
            showModalAction({
                modalName: ModalName.CONNECT_WALLET_MODAL,
                modalTitle: 'Connect Wallet',
            }),
        );

    return (
        <div className={classes.block}>
            <div className={classes.wrapper}>
                <LoggedInProvider
                    haveAuth={<SelectNetwork handleShowBanner={handleSetBannerState} />}
                />
            </div>
            <div className={classes.wrapper}>
                <LoggedInProvider
                    haveAuth={<ConnectedWalletInfo />}
                    haveNoAuth={
                        <ButtonStroke
                            onClick={handleConnectWalletClick}
                            className={classes.walletButton}
                        >
                            Connect Wallet
                        </ButtonStroke>
                    }
                />
            </div>
            <div className={classes.wrapper}>
                <LoggedInProvider haveAuth={<UserControls />} />
            </div>
        </div>
    );
};
