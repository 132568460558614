import { createUseStyles } from 'react-jss';
import { fontSize, radius, spacing } from '@blink/components';

export const useConnectWalletStyles = createUseStyles({
    block: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: spacing['03'],
    },
    wrapper: {
        height: '44px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: radius.overall,
    },
    walletButton: {
        width: 152,
        fontSize: fontSize.md,
    },
});
