import { put, select, takeLatest, call } from 'redux-saga/effects';
import { Contract, ethers, formatUnits } from 'ethers';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Errors } from '@blink/components/src/constants/errors';

import { Store } from '../store';
import {
    Agreement,
    AgreementDetails,
    Token,
    getAgreementDetails,
    getAgreements,
    getTokens,
} from '../api/borrow';
import IERCAbi from '../abi/IERCAbi.json';
import AgreementABI from '../abi/AgreementABI.json';
import { agreementErrorAction } from 'src/store/errors/actions';
import { mapAgreementData } from 'src/utils/helpers';
import { GET_AGREEMENTS } from 'src/store/agreements/constants';
import { setAgreementsAction } from 'src/store/agreements/actions';
import { setTokensAction } from 'src/store/tokens/actions';

function* getAgreementsSaga(): Generator<any, void, any> {
    try {
        const {
            type,
            blockchainName,
            walletAddress,
        }: { type: any; blockchainName: any; walletAddress: string } = yield select(
            (state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
            }),
        );
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;

        if (wallet) {
            const walletProvider: any = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );
            const signer: ethers.Signer = yield provider.getSigner();

            const agreements: Agreement[] = yield call(getAgreements);

            if (agreements.length === 0) {
                return;
            }

            for (const agreement of agreements) {
                const agreementAddress = agreement.address || '';

                try {
                    const agreementContract = new Contract(agreementAddress, AgreementABI, signer);
                    const result = yield agreementContract.info();

                    const metadata = result[0];
                    const state = result[1];

                    agreement.collaterals = Array.from(metadata.collaterals);
                    agreement.lenders = Array.from(metadata.lenders);
                    agreement.borrowers = Array.from(metadata.borrowers);
                    agreement.tokens = Array.from(state.tokens);

                    const agreementDetails: AgreementDetails = yield call(
                        getAgreementDetails,
                        agreementAddress,
                    );

                    Object.assign(agreement, agreementDetails);
                } catch (e: any) {
                    yield put(agreementErrorAction({ message: e.message || Errors.AGREEMENTS }));
                    console.error(e);
                }
            }

            const tokens: Token[] = yield call(getTokens);
            console.log('tokens', tokens);
            for (const token of tokens) {
                const ethAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
                const tokenAddress = token.address || '';
                if (tokenAddress !== ethAddress) {
                    try {
                        const tokenContract = new Contract(tokenAddress, IERCAbi, signer);

                        const balance = yield tokenContract.balanceOf(walletAddress);

                        const balanceInToken: string = formatUnits(balance, token?.decimals);
                        token.balance = balanceInToken;

                        token.apyRewards = '0'; //Temporary hardcoded AR-658
                    } catch (e: any) {
                        yield put(agreementErrorAction({ message: e.message || Errors.TOKENS }));
                        console.log(e);
                    }
                }
            }

            yield put(setTokensAction(tokens as Token[]));

            const resultAgreements = mapAgreementData(agreements, tokens);

            for (const agreement of resultAgreements) {
                try {
                    const agreementContract = new Contract(agreement.address, AgreementABI, signer);

                    const result = yield agreementContract.info();
                    const metadata = result[0];

                    agreement.thresholdOnTotalDeposit = formatUnits(
                        metadata.thresholdOnTotalDeposit,
                        agreement.leverage.decimals,
                    );

                    const totalDeposited = yield agreementContract.totalDeposited();
                    agreement.totalDeposited = formatUnits(
                        totalDeposited,
                        agreement.leverage.decimals,
                    );
                } catch (e: any) {
                    yield put(agreementErrorAction({ message: e.message || Errors.TOKENS }));
                    console.log(e);
                }
            }

            yield put(setAgreementsAction(resultAgreements as Agreement[]));
        }
    } catch (e: any) {
        yield put(agreementErrorAction({ message: e.error || Errors.AGREEMENTS }));
        console.error(e);
    }
}

export function* agreementsWatcher() {
    yield takeLatest(GET_AGREEMENTS, getAgreementsSaga);
}
