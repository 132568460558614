import { Centrifuge } from 'centrifuge';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CentrifugeApi } from 'src/api/centrifuge';
import {
    setUserBorrowsMaintenance,
    setUserBorrowsMaintenanceHistory,
} from 'src/store/borrows/actions';

export const useCentrifuge = () => {
    const [centrifuge, setCentrifuge] = useState<Centrifuge | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!centrifuge) {
            CentrifugeApi.on('connected', function (ctx) {
                console.log('[WS] Connected over ' + ctx.transport);
            });

            CentrifugeApi.connect();
            setCentrifuge(CentrifugeApi);
        }

        return () => {
            if (centrifuge) {
                centrifuge.disconnect();
            }
        };
    }, []);

    const subscribeToChannel = (channel: string) => {
        if (!centrifuge) {
            console.error('Centrifuge is not initialized');
            return;
        }

        const existingSubscription = centrifuge.getSubscription(channel);
        if (existingSubscription) {
            console.log(`Already subscribed to channel: ${channel}`);
            return;
        }

        const subscription = centrifuge
            ?.newSubscription(channel)
            .on('subscribed', function () {
                subscription?.history({ limit: 100 }).then(
                    function (ctx: any) {
                        dispatch(setUserBorrowsMaintenanceHistory(ctx.publications));
                    },
                    function (err) {
                        console.log(channel + `History error: ` + err);
                    },
                );
                console.log(channel + ' Subscribed to risk factor');
            })
            .on('publication', function (ctx: any) {
                dispatch(setUserBorrowsMaintenanceHistory([ctx]));
                dispatch(setUserBorrowsMaintenance(ctx.data));
            });
        subscription?.subscribe();
    };

    return { centrifuge, subscribeToChannel };
};
