import { createUseStyles } from 'react-jss';

import { color, radius, spacing } from '../variables';

export const useButtonGroupStyles = createUseStyles({
    btn: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: radius.overall,
        '& .ant-radio-button-wrapper': {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '48px',
            borderColor: 'transparent',
            borderInlineStartWidth: '1px',
            color: color.content.secondary,
            borderRadius: radius.overall,
            paddingInline: spacing['06'],
            background: color.background.primary,
            '&:not(:first-child):before': {
                borderColor: color.border.opaque,
                backgroundColor: color.border.opaque,
                insetBlockStart: '0px',
                height: '66%',
                marginTop: '6%',
                zIndex: 0,
            },
            '&:hover': {
                color: color.content.primary,
            },
            '&.ant-radio-button-wrapper-checked:not(:first-child):before': {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
            '&.ant-radio-button-wrapper-checked': {
                border: `2px solid ${color.border.accent}`,
                zIndex: 100,
            },
            '& .ant-radio-button-checked + span': {
                color: color.content.primary,
            },
        },
    },
});
