import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUserSuppliesStyle = createUseStyles({
    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    noSuppliesBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: `${spacing['08']} ${spacing['10']}`,
        background: color.background.primary,
        border: `1px solid ${color.border.opaque}`,
        '& .ant-skeleton-input': {
            width: '250px !important',
            height: '20px !important',
            minWidth: '250px !important',
            borderRadius: 4,
        },
        '& svg': {
            animation: '$rotate 5s linear infinite',
        },
    },
    noSuppliesTitle: {
        color: color.content.primary,
        fontSize: fontSize.lg,
        marginTop: spacing['04'],
        letterSpacing: '-0.019em',
    },
    noSuppliesSubtitle: {
        textAlign: 'center',
        color: color.content.secondary,
        marginTop: spacing['03'],
        letterSpacing: '-0.019em',
    },
});
