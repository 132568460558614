export const Errors = {
    ASSETS_TO_SUPPLY: 'Not possible to get assets to supply',
    USERS_SUPPLIES: 'Not possible to get your supplies',
    USER_BORROWS: 'Not possible to get your borrows',

    ADD_WALLETS: 'Not possible to add wallets',
    ADD_NETWORK: 'Not possible to add networks',

    TOKENS: 'Not possible to get tokens',
    AGREEMENTS: 'Not possible to get agreements',
    ORGANIZATIONS: 'Not possible to get organizations',
};
