import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useBorrowStyle = createUseStyles({
    wrapper: {},
    arrow: {
        transform: 'rotate(-90deg)',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        color: color.content.primary,
    },
    root: {
        display: 'flex',
        width: 910,
        marginTop: 24,
    },
    left: {
        flex: 1.05,
        padding: `${spacing['07']} ${spacing['07']} ${spacing['07']} 0`,
        borderRadius: 4,
        '& img + div': {
            fontWeight: 600,
        },
        '& .ant-input': {
            fontWeight: 600,
        },
        '& .assetsWrapper button > .root > div': {
            fontWeight: 600,
        },
    },
    noCollateralAlert: {
        marginTop: spacing['04'],
    },
    assetInputRoot: {
        marginTop: spacing['04'],
    },
    collateralAssetRoot: {
        marginTop: 28,
        position: 'relative',
    },
    collateralAssetNotAllowed: {
        marginTop: 28,
        position: 'relative',
        '&:before': {
            content: '""',
            background: 'transparent',
            height: 'calc(100% - 30px)',
            width: '100%',
            position: 'absolute',
            marginTop: '30px',
            zIndex: '9',
            opacity: '15%',
            cursor: 'not-allowed',
        },
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        flex: 1,
        padding: `${spacing['07']} 0 ${spacing['07']} ${spacing['07']}`,
        borderRadius: 4,
        '& .assetsWrapper button > .root > div': {
            fontWeight: 600,
        },
    },
    rightCardWrapper: {
        borderRadius: 4,
        padding: spacing['06'],
        border: `1px solid ${color.border.opaque}`,
    },
    rightCardWrapperNoSidePadding: {
        borderRadius: 4,
        padding: `${spacing['06']} 0px`,
        border: `1px solid ${color.border.opaque}`,
    },
    secondaryText: {
        color: color.content.secondary,
        fontWeight: 400,
    },
    borrowLimitRow: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: `${spacing['05']} 0`,
    },
    progress: {
        '& .ant-progress-bg': {
            height: '2px !important',
        },
    },
    borrowRates: {
        marginTop: spacing['04'],
        paddingTop: spacing['06'],
        borderTop: `1px solid ${color.border.opaque}`,
    },
    borrowApyWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    borrowApy: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
    },
    borrowImg: {
        width: 24,
        height: 24,
    },
    submitButton: {
        fontSize: fontSize.md,
        width: '100%',
    },
    btnWrapper: {
        display: 'flex',
        paddingTop: spacing['04'],
        borderTop: `1px solid ${color.border.opaque}`,
    },
    spinWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: `${spacing['08']} 0`,
    },
    text: {
        marginBottom: spacing['03'],
        fontWeight: 600,
    },
});
