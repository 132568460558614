import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useWalletInfoModalStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${spacing['07']} 0 ${spacing['03']}`,
        width: 432,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    walletNameWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletName: {
        marginLeft: spacing['04'],
        fontSize: fontSize.lg,
    },
    buttonWrapper: {
        display: 'flex',
        gap: spacing['04'],
    },
    button: {
        width: 96,
    },
    buttonPrimary: {
        background: color.background.alwaysLight,
        color: color.background.primary,
        marginRight: spacing['03'],
    },
    address: {
        color: color.content.secondary,
        marginTop: spacing['04'],
        paddingBottom: spacing['04'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    textButton: {
        color: `${color.content.primary} !important`,
        width: 'fit-content',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        margin: `${spacing['05']} 0 ${spacing['03']}`,
        gap: spacing['06'],
    },
    walletConnectWrapper: {
        paddingTop: spacing['04'],
        paddingBottom: spacing['04'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    walletConnectInputWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectInput: {
        height: '44px',
        marginRight: spacing['03'],
        padding: `0px ${spacing['04']}`,
        fontSize: fontSize.md,
    },
    walletConnectButton: {
        display: 'flex',
        alignItems: 'center',
        marginRight: spacing['07'],
    },
    connected: {
        display: 'flex',
        alignItems: 'center',
        width: 432,
        padding: `${spacing['04']} ${spacing['05']}`,
        gap: spacing['03'],
        alignSelf: 'stretch',
        margin: `${spacing['07']} 0 ${spacing['03']}`,
        borderRadius: radius.overall,
        background: color.background.positive,
        color: color.content.positive,
        fontWeight: 400,
        '& svg': {
            height: 16,
            width: 16,
        },
    },
    walletConnectLogo: {
        width: 27,
        height: 27,
        marginLeft: spacing['02'],
    },
    walletConnectSubmitButtonWrapper: {
        minWidth: 112,
        display: 'flex',
        alignItems: 'center',
        '& button': {
            height: 44,
            width: '100%',
            fontSize: fontSize.md,
        },
    },
    walletConnectButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectSubmitButton: {
        color: color.content.primary,
    },
});
